<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>医患管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/setDoctor' }"
        >医生管理</el-breadcrumb-item
      >
      <el-breadcrumb-item
        :to="{ path: '/setDoctor/addCase', query: { id: doc_id } }"
        >好转病例</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form
      label-width="120px"
      ref="form"
      class="casedetail"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="用户ID" prop="cust_id">
        <el-input
          v-model="form.cust_id"
          @keyup.native="onlynumber"
          placeholder="请填入纯数字的用户ID"
        ></el-input>
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="年龄" prop="age">
        <el-input v-model="form.age"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="sex">
        <el-select v-model="form.sex" placeholder="请选择性别">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择疾病类别" prop="ill_lib_id">
        <el-select
          v-model="ill_lib_id_name"
          filterable
          value-key="name"
          placeholder="请选择对应的疾病类别"
          @change="selectdistributedp"
        >
          <el-option
            v-for="item in illoptions"
            :key="item.id"
            :label="item.name"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所患疾病" prop="ill">
        <el-input v-model="form.ill"></el-input>
      </el-form-item>
      <el-form-item label="排序">
        <el-input
          style="float: left"
          type="number"
          v-model="form.sort"
          autocomplete="off"
          placeholder="请输入数字"
        >
        </el-input>
        <span style="float: left; color: #909399; width: 100%; text-align: left"
          >数字越大排名越靠前</span
        >
      </el-form-item>
      <el-form-item label="封面图片" prop="video_pic">
        <upload :src.sync="form.video_pic"></upload>
      </el-form-item>
      <el-form-item label="视频地址链接" prop="video">
        <el-input v-model="form.video"></el-input>
      </el-form-item>
      <el-form-item label="头像">
        <upload :src.sync="form.headimg"></upload>
      </el-form-item>
      <el-form-item label="病情概述">
        <el-input
          v-model="form.summary"
          rows="5"
          type="textarea"
          maxlength="500"
          placeholder="最长输入500字"
        ></el-input>
      </el-form-item>
      <el-form-item label="治疗前">
        <el-input v-model="form.ill_before" rows="5" type="textarea"></el-input>
      </el-form-item>
      <el-form-item label="治疗后">
        <el-input v-model="form.ill_after" rows="5" type="textarea"></el-input>
      </el-form-item>
      <el-form-item label="治疗简介">
        <FullEditor id="addCaseDetail" rows="10" upload="/"></FullEditor>
      </el-form-item>
      <div>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="sub">确 定</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
import upload from "../components/upload";
import FullEditor from "../components/FullEditor.vue";
export default {
  components: {
    upload,
    FullEditor,
  },
  data() {
    return {
      id: "",
      doc_id: "",
      type: "",
      title: "添加病例",
      options: [
        {
          value: "男",
          label: "男",
        },
        {
          value: "女",
          lebel: "女",
        },
      ],
      desc: "",
      form: {
        name: "",
        age: "",
        sex: "",
        video: "",
        headimg: "",
        desc: "",
        doc_id: "",
        ill_lib_id: "",
        video_pic: "",
        summary: "",
        sort: "",
        ill: "",
        ill_before: "",
        ill_after: "",
        cust_id: "",
      },
      illoptions: [],
      isvideocanplay: false,
      rules: {
        // cust_id: [
        //   { required: true, message: "请输入用户编号", trigger: "blur" },
        // ],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        age: [{ required: true, message: "请输入年龄", trigger: "blur" }],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        ill_lib_id: [
          { required: true, message: "请选择疾病", trigger: "change" },
        ],
        ill: [{ required: true, message: "请输入所患疾病", trigger: "blur" }],
        video_pic: [
          { required: true, message: "请上传封面图片", trigger: "blur" },
        ],
        video: [{ required: true, message: "请填写视频地址", trigger: "blur" }],
      },
      ill_name: "",
      ill_lib_id_name: "",
      ill_lib_id: "",
    };
  },
  watch: {
    "form.video": {
      deep: true,
      handler(value, oldValue) {
        this.check(value);
      },
    },
  },
  mounted() {
    this.form.doc_id = this.$route.query.doc_id;
    this.doc_id = this.$route.query.doc_id;
    this.type = this.$route.query.type;
    if (this.type != "new") {
      this.title = "编辑病例";
      this.id = this.$route.query.id;
      this.axios
        .get("/gu/get_gu_showcust_by_id?id=" + this.id)
        .then((rs) => {
          if (rs.data.code == 0) {
            // console.log(rs);
            this.form = rs.data.result;
            if (rs.data.result.cust_id == null) {
              this.form.cust_id = "0";
            } else {
              this.form.cust_id = rs.data.result.cust_id;
            }
            this.form.ill = rs.data.result.ill_name;
            this.ill_lib_id_name = rs.data.result.ill_lib_id;
            // this.desc = this.htmlDecode(rs.data.result.desc);
            let txt = this.htmlDecode(rs.data.result.desc);
            this.$nextTick(() =>
              setTimeout(() => {
                tinymce.activeEditor.setContent(txt);
              }, 300)
            );
          } else {
            console.log(rs);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    this.getill();
  },
  methods: {
    onlynumber() {
      var len1 = this.form.cust_id.substr(0, 1);
      var myRegExp = new RegExp("^[a-zA-Z]");
      if (myRegExp.exec(len1)) {
        console.log("以字母开头");
        this.form.cust_id = "0";
      }
    },
    check(value) {
      var video = document.createElement("video");
      video.src = value;
      var _this = this;
      video.onerror = function () {
        // alert("Can not play");
        _this.isvideocanplay = false;
        _this.$message({
          message: "请填写正确的视频地址",
          type: "error",
        });
      };
      video.oncanplay = function () {
        // alert("Can start playing video");
        _this.isvideocanplay = true;
      };
    },
    getill() {
      this.axios
        .get("/gu/get_gu_ill_lib")
        .then((res) => {
          if (res.data.code == 0) {
            this.illoptions = res.data.result;
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    htmlEncode: function (html) {
      //1.首先动态创建一个容器标签元素，如DIV
      var temp = document.createElement("div");
      //2.然后将要转换的字符串设置为这个元素的innerText或者textContent
      temp.textContent != undefined
        ? (temp.textContent = html)
        : (temp.innerText = html);
      //3.最后返回这个元素的innerHTML，即得到经过HTML编码转换的字符串了
      var output = temp.innerHTML;
      temp = null;
      return output;
    },
    /*2.用浏览器内部转换器实现html解码（反转义）*/
    htmlDecode: function (text) {
      //1.首先动态创建一个容器标签元素，如DIV
      var temp = document.createElement("div");
      //2.然后将要转换的字符串设置为这个元素的innerHTML(ie，火狐，google都支持)
      temp.innerHTML = text;
      //3.最后返回这个元素的innerText或者textContent，即得到经过HTML解码的字符串了。
      var output = temp.innerText || temp.textContent;
      temp = null;
      return output;
    },
    removeHTMLTag(str) {
      return str.replace(/<[^>]+>/g, "");
    },
    cancel() {
      this.$router.push({ path: "/addCase", query: { id: this.doc_id } });
    },
    sub() {
      let url = "",
        url1 = "";
      let str = "";
      if (this.type == "new") {
        url = "/gu/insert_gu_showcust";
        url1 = "/item/insert_gu_item";
        str = "添加成功";
      } else {
        url = "/gu/update_gu_showcust";
        // url1 = "/item/update_gu_item";
        str = "修改成功";
        this.form.id = this.id;
      }
      let data = tinyMCE.activeEditor.getContent();
      this.form.desc = this.htmlEncode(data);
      // console.log(this.form);

      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (!this.isvideocanplay) {
            this.$message({
              message: "请填写正确的视频地址",
              type: "error",
            });
            return;
          }
          console.log("submit!");
          var newform = {
            title: this.form.name,
            pic: this.form.video_pic,
            type: "1",
            content_type: "2",
            doc_id: this.form.doc_id,
            ill_lib_id: this.form.ill_lib_id,
            name: this.form.name,
            sex: this.form.sex,
            age: this.form.age,
            headimg: this.form.headimg,
            x_cust_id: this.form.cust_id,
            content: this.form.video,
            des: this.removeHTMLTag(data),
          };
          // console.log(this.form);
          // console.log(newform)
          if (this.type == "new") {
            //往视频管理列表中插数据
            this.axios
              .post(
                "/tag/check_gu_tag_by_illlibid",
                this.qs.stringify({
                  ill_lib_id: this.form.ill_lib_id,
                  ill_lib_name: this.ill_name,
                })
              )
              .then((res) => {
                if (res.data.code == 0) {
                  this.axios
                    .post(url1, this.qs.stringify(newform))
                    .then((res) => {
                      if (res.data.code == 0) {
                        //默认审核好转病例
                        // this.axios
                        //   .post(
                        //     "/item/update_gu_item_audit",
                        //     this.qs.stringify({ id: res.data.id, is_audit: 0 })
                        //   )
                        //   .then((res) => {
                        //     console.log(res);
                        //   });
                        //绑定标签
                        this.bangdingjibing(this.ill_lib_id, res.data.id);
                      }
                    });
                }
              });
          }
          //医生好转病例接口的增加和修改
          this.axios
            .post(url, this.qs.stringify(this.form))
            .then((rs) => {
              if (rs.data.code == 0) {
                // this.form = {};
                this.$message({
                  message: str,
                  type: "success",
                });
                this.$router.push({
                  path: "/setDoctor/addCase",
                  query: { id: this.doc_id },
                });
              } else {
                this.$message(rs.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("error submit!!");
          this.$message({
            message: "请补全信息",
            type: "error",
          });
          return false;
        }
      });
      //
    },
    selectdistributedp(val) {
      console.log(val);
      this.ill_lib_id_name = val.name;
      this.form.ill_lib_id = val.id;
      this.ill_lib_id = val.id;
      this.ill_name = val.name;
    },
    bangdingjibing(illid, _id) {
      this.axios
        .post(
          "/item/update_gu_item_illlibid",
          this.qs.stringify({ ill_lib_id: illid, id: _id })
        )
        .then((res) => {
          if (res.data.code == 0) {
            console.log("绑定成功");
          }
        });
    },
  },
};
</script>
<style>
.el-form {
  width: 80%;
  margin-top: 50px;
}

.el-select {
  width: 40%;
  float: left;
}
.casedetail .el-input {
  width: 80%;
  float: left;
}
</style>
